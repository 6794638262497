*{
  padding: 0;
  border: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

a:hover{
  opacity: 90%;
  border-bottom: 4px solid transparent;
  transition: ease-in-out cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

li a:hover{
  border-bottom: 4px solid rgb(46, 46, 184);
}

/*Menu*/

.menu-bar{
  width: 100%;
  padding: 20px;
  padding-bottom: 5px;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  color: white;
}

.menu-list{
  display: flex;
  list-style-type: none;
  justify-content: space-around;
  min-width: 100%;
  font-size: 110%;
}

.menu-title{
  font-family: "Nova Round", system-ui;
  font-weight: 400;
  font-style: normal;
}

.blob{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: repeat;
    background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M738 645.5Q668 791 468.5 846T196 700.5Q123 500 229.5 358T500 216q164 0 236 142t2 287.5Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23444cf7%22 d=%22M738 645.5Q668 791 468.5 846T196 700.5Q123 500 229.5 358T500 216q164 0 236 142t2 287.5Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

/*Landing*/

.main-container-flex{
  display: flex;
  width: 100%;
  height: 90vh;
  background: rgb(12, 12, 27)
}

.main-page-flex{
  display: flex;
  flex-direction: column;
  width:100%;
  gap: 20px;
}

#main-page-padding-exception{
  padding-top: 10px;
  padding-bottom: 20px;
}

.main-page-h1{
  text-align: center;
  color: white;
  margin-bottom: 10px;
  margin-left: 100px;
  margin-right: 100px;
}

.main-page-p{
  text-align: center;
  color: white;
  margin-bottom: 50px;
  margin-left: 100px;
  margin-right: 100px;
}

.main-page-img{
  width: 80%;
  padding: 10px;
  height: 100%;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.main-highlight{
  display: flex;
  width:100%;
  justify-content: left;
}

.main-simple-line{
  margin-left: 100px;
  background-color: rgb(46, 46, 184);
  width: 100px;
  height: 20px;
}

.main-button-highlight{
  width: 10em;
  height: 3em;
  cursor: pointer;
  border-radius: 10px;
  font-size: 100%;
  background-color: rgb(46, 46, 184);
  color: white;
  margin-left: 100px;
}

.main-button-highlight:hover{
  background-color: rgba(46, 46, 184, 0.877);
}

/*About Me*/

.about-page-container{
  width: 100%;
  background-color: #FFDE59;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, minmax(fit-content, 1fr));
  gap: 40px;
  justify-content: space-around;
}

.about-title{
  color: #0c0c1b;
}

.about-section-card{
  margin-top: 50px;
  display: flex;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  margin-bottom: 40px ;
}

.about-section-skills{
  display: grid;
  width: 50%;
  grid-template-columns: 0.2fr 1fr 0.2fr;
  grid-template-rows: 1fr;
  padding: 20px;
  overflow-x: hidden;
  border: 3px solid #0c0c1b;
  border-radius: 10px;
  margin-bottom: 40px;
}

.carousel-holder{
  overflow-x: hidden;
  display: flex;
  max-width: 100%;
  justify-content: flex-start;
}

.carousel{
  display: flex;
  max-width: 100%;
  justify-content: flex-start;
  gap: 30px;
}

.arrow{
  color: #0c0c1b;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  background-color: #FFDE59;
}

/*Projects*/

.container-projects{
  width: 100%;
  background-color:#0066FF;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-columns: 1fr;
}

.projects-title-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: space-around;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.projects-title{
  width: fit-content;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-self: flex-start;
}

.projects-section{
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/*Contact*/

.contacts-container{
  background-color: #9ae06d;
  width: 100%;
  color: #0c0c1b;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-columns: 1fr;
}

.contact-title{
  padding: 20px;
  height: fit-content;
}

.contact-section{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  flex-wrap: wrap;
}

.contactball-style{
  display: flex;
  flex-direction: column;
}

.ball{
  width: 5rem;
  height: 5rem;
  background-color: #317d83;
  padding: 20px;
  border-radius: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.ball:hover{
  -webkit-box-shadow: 0px 0px 17px 4px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 17px 4px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 17px 4px rgba(0,0,0,0.3);
}

/*Features*/

.spacer{
  aspect-ratio: 960/50;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/*Card*/

.card-primary{
  min-width: fit-content;
  width: 30%;
  height: 50%;
  margin: 10px;
  color: #0c0c1b;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  background-color: #FFDE59;
}

.card-primary:hover{
  box-shadow: 0px 10px 21px 5px rgba(0,0,0,0.1);
  transform: scale(1.1);
}

.card-secondary{
  min-width: 10vw;
  height: 20vh;
  margin: 10px;
  color:#FFDE59;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  background-color: #0c0c1b;
}

.card-secondary:hover{
  border: 1px solid #0c0c1b;
  transform: scale(1.1);
}

.skill-level{
  border: 1px solid #FFDE59;
  width: 90%;
  height: 10%;
  display: flex;
  justify-content: flex-start;
}

.skill-bar-advanced{
  background-color: #FFDE59;
  width: 100%;
  height: 100%;
}

.skill-bar-medium{
  background-color: #FFDE59;
  width: 80%;
  height: 100%;
}

.skill-bar-basic{
  background-color: #FFDE59;
  width: 30%;
  height: 100%;
}

.card-terciary {
  background-image: url('');
  border-radius: 10px;
  color: #0c0c1b;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28vw;
  height: 15vw; 
  margin: 1%;
  float: left;
  position: relative;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  background-size: cover;
  background-position: center;
}

.card-terciary-title{
  display: none;
}

.card-terciary-title:hover{
  display: flex;
  transform: scale(1.2);
}

.filter{
  width: 100%;
  height: 100%;
  background-color: #0c0c1b7c;
  border-radius: 10px;
  opacity: 0;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
}

.filter:hover{
  opacity: 100;
}

@media (min-width: 900px){
  #element-scale-hover:hover{
    transform: scale(1.1);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  }
}

@media (max-width: 900px) {

  /*Menu*/

  .menu-bar{
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .menu-list{
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    width: 100%;
    gap: 10px;
  }

  /*Landing*/

  .main-container-flex{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: fit-content;
    background: linear-gradient(90deg, rgb(12, 12, 27) 0%, rgb(19, 19, 37) 100%)
  }

  .main-page-flex{
    display: flex;
    flex-direction: column;
    width:100%;
    gap: 20px;
    padding-top: 120px;
  }

  #main-page-padding-exception{
    padding-top: 0;
  }

  .main-page-h1{
    text-align: center;
    color: white;
    margin-bottom: 10px;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 200%;
  }

  .main-page-p{
    text-align: center;
    color: white;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .main-simple-line{
    width: 0px;
    height: 0;
  }

  .main-highlight{
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    gap: 30px;
  }

  .main-button-highlight{
    width: 10em;
    height: 3em;
    cursor: pointer;
    border-radius: 10px;
    font-size: 100%;
    background-color: rgb(46, 46, 184);
    color: white;
    margin: 0;
  }

  /*About*/

  .about-section-skills{
    width: 100%;
    gap: 10px;
    height: fit-content;
  }

  .about-section-skills{
    border: 0;
  }

  /*Card*/

  .card-primary{
    width: 50%;
    justify-content: space-around;
    height: auto;
  }

  .card-secondary{
    min-width: 40vw;
  }

  .card-terciary{
    background-color: aliceblue;
    border-radius: 10px;
    width: 90vw;
    height: 60vw;
  }
}
